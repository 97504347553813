import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {CouponDetail} from 'features/search/components'
import {selectIsIframe} from 'features/auth/redux/authSelector'
import {couponFaqsData} from 'shared/constants/faqs'
import {SEO} from 'shared/components'

const GetCoupon = () => {
    const isFrame = useSelector(selectIsIframe)

    useEffect(() => {
        window.scrollTo({top: 0})
    }, [])

    return (
        <div className='c-coupon'>
            <SEO title='Get Discount - Discount Drug Network'
                 description='Use your Discount Drug Network coupon to save on your medication'/>
            <CouponDetail isFrame={isFrame}/>
            <p class="c-card-screen__date">
                {new Date().toLocaleString() + ''}
            </p>
            {!isFrame && <div className='c-coupon__faqs'>
                <div className='c-paragraph'><b className="c-coupon__faqs__title">FAQs</b></div>
                {couponFaqsData.map(({question, answer}) => (
                    <React.Fragment key={question}>
                        <div className='c-paragraph--loose'>
                            <b>Q:</b> {question}
                        </div>
                        <div className='c-paragraph--loose'>
                            <b>A:</b> {answer}
                        </div>
                    </React.Fragment>
                ))}
            </div>}
        </div>
    )
}

export default GetCoupon
