export const DRUG_NAME_LABEL = 'DRUG NAME';
export const DRUG_NAME_PLACEHOLDER = 'What drug are you looking for?';
export const ZIP_CODE_LABEL = 'ZIP CODE';
export const BRAND_INDICATOR = 'TYPE';
export const GENERIC_LABEL = "GENERIC";
export const BRAND_LABEL = "BRAND";
export const ZIP_CODE_PLACEHOLDER = 'Where are you?';

export const cardholderID = '5599';
export const rxBIN = '015558';
export const rxPCN = 'HT';

export const determineGroupID = () => {
    // Check the current pathname to set groupID
    const currentPath = window.location.pathname;

    if (currentPath === '/volunteer') {
        return '5598';
    }

    // Default groupID if no specific path matches
    return '5599';
};

export const groupID = determineGroupID();
export const textGroupID = groupID;
export const defaultGroupId = groupID;
