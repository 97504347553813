import { createReducer } from '@reduxjs/toolkit'
import {
  FORM,
  STRENGTH,
  QUANTITY,
  INDEPENDENT,
  CATEGORIES,
  CATEGORY_FIELD,
  cardholderID,
  groupID,
  rxBIN,
  rxPCN,
  GSN
} from 'shared/constants'
import searchTypes from './searchTypes'
import unionBy from 'lodash/unionBy'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

const groupDrugsByCategory = (drugs) => {
  try {
    const drugGroups = groupBy(drugs, CATEGORY_FIELD)

    // National (1) & Grocery (2) in one group, Other (0) in remaining group
    const combinedGroups = [
      sortBy([...drugGroups[0]], 'price'),
      sortBy(drugGroups[2] ? [...drugGroups[1], ...drugGroups[2]] : [...drugGroups[1]], 'price'),
    ]

    const drugCategories = map(combinedGroups, (group, category) => {
      const drugCategory = category !== 'null' ? category : INDEPENDENT
      const categoryData = CATEGORIES[drugCategory]
      const title = categoryData ? categoryData.title : category
      const order = categoryData ? categoryData.order : 0
      const icon = categoryData ? categoryData.icon : 'store'
      const drugsGroup = groupBy(group, 'pharmacyName')
      return {
        category: drugCategory,
        title,
        drugsGroup,
        order,
        icon
      }
    })
    return sortBy(drugCategories, 'order');
  } catch (error) {
    console.log(error.message);
    return [];
  }
}

const defaultState = {
  drugs: [],
  count: 0,
  drugNames: [],
  input: {
    drugName: '',
    zip: '',
    brandIndicator: 'G',
  },
  drugInfo: {},
  initialForms: [],
  forms: [],
  quantities: [],
  strengths: [],
  names: [],
  filters: {},
  discount: {
    cardholderID,
    groupID,
    rxBIN,
    rxPCN
  },
  meta: {
    drugDataLoaded: false,
    isLoadingDrugResults: false,
    isLoadingDrugNames: false,
    isLoadingDiscountInfo: false
  }
}

const findSelected = ({ isSelected }) => isSelected
const mergeOptions = (options, initial, field) => {
  const item = options.find(findSelected)
  if (!item) {
    return options
  }
  const val = item[field]
  const original = initial.map(i => ({ ...i, isSelected: i[field] === val }))
  return unionBy(original, options, field)
}

export default createReducer(defaultState, {
  [searchTypes.REQUEST_DRUGS_BEGIN]: state => {
    state.meta.drugDataLoaded = false
    state.meta.isLoadingDrugResults = true
  },
  [searchTypes.REQUEST_DRUGS_SUCCESS]: (state, action) => {
    const { res, query, filter } = action.payload
    const { drugInfo, drugs, forms, quantities, strengths, names } = res
    const { gsn } = drugInfo
    if (!gsn) {
      state.initialForms = forms
    }

    if (filter === FORM) {
      state.forms = mergeOptions(forms, state.initialForms, FORM)
      state.strengths = strengths
      state.quantities = quantities
    } else if (filter === STRENGTH) {
      state.forms = mergeOptions(forms, state.initialForms, FORM)
      state.strengths = mergeOptions(strengths, state.strengths, STRENGTH)
    } else if (filter === QUANTITY) {
      state.quantities = quantities
    } else {
      state.forms = forms
      state.quantities = quantities
      state.strengths = strengths
    }

    const form = state.forms.find(findSelected)
    const quantity = state.quantities.find(findSelected)
    const strength = state.strengths.find(findSelected)

    state.filters = {
      gsn: form && form.gsn,
      form: form && form.gsn,
      qty: quantity && quantity.quantity,
      quantity: quantity && quantity.quantity,
      strength: strength && strength.gsn
    }

    state.drugInfo = {
      ...state.input,
      ...drugInfo
    }
    state.count = drugs.length
    state.drugs = groupDrugsByCategory(drugs)
    state.names = names
    state.meta.isLoadingDrugResults = false
    state.meta.drugDataLoaded = true

    try {
      const GAPayload = {
        event: 'drugSearch', // this is the name of the event. (constant)
        searchDetails: {
          drug_name: query.drugName,                                  // the name of the drug being searched. (dynamic)
          zip_code: query.zip.toString(),                             // the zip code where the user is searching for the drug. (dynamic)
          brand_indicator: query.brandIndicator, //
          domain_name: window.location === window.parent.location      // the domain on which the user searched for the drug, check for iFrame (dynamic)
            ? document.location.href
            : document.referrer,
          form: get(form, ['form'], ''),                              // the form of the drug being searched
          quantity: get(quantity, ['quantity'], '').toString(),       // the quantity of the drug
          dosage: get(strength, ['strength'], ''),                    // the dosage of the drug
        },
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(GAPayload);
    } catch (e) {
      console.error(e)
    }
  },
  [searchTypes.REQUEST_DRUGS_FAIL]: state => {
    state.meta.isLoadingDrugResults = false
    state.meta.drugDataLoaded = true
  },
  [searchTypes.AUTOCOMPLETE_DRUG_NAME_BEGIN]: state => {
    state.meta.isLoadingDrugNames = true
  },
  [searchTypes.AUTOCOMPLETE_DRUG_NAME_SUCCESS]: (state, action) => {
    state.drugNames = action.payload
    state.meta.isLoadingDrugNames = false
  },
  [searchTypes.AUTOCOMPLETE_DRUG_NAME_FAIL]: state => {
    state.meta.isLoadingDrugNames = false
  },
  [searchTypes.SET_SEARCH_DRUG_NAME]: (state, action) => {
    state.input.drugName = action.payload || ''
  },
  [searchTypes.SET_SEARCH_ZIPCODE]: (state, action) => {
    state.input.zip = action.payload || ''
  },
  [searchTypes.SET_SEARCH_BRANDINDECATOR]: (state, action) => {
    state.input.brandIndicator = action.payload || 'G'
  },
  [searchTypes.SET_SEARCH_FILTER]: (state, action) => {
    const { key, field, value, newGsn } = action.payload
    state.filters[key] = value
    state.filters[field] = value

    state.filters[FORM] = newGsn
    state.filters[GSN] = newGsn
    state.filters[STRENGTH] = newGsn

  },
  [searchTypes.REQUEST_DISCOUNT_INFO_BEGIN]: state => {
    state.meta.isLoadingDiscountInfo = true
    state.meta.drugDataLoaded = false
  },
  [searchTypes.REQUEST_DISCOUNT_INFO_SUCCESS]: (state, action) => {
    state.discount = action.payload
    state.meta.isLoadingDiscountInfo = false
    state.meta.drugDataLoaded = true
  },
  [searchTypes.REQUEST_DISCOUNT_INFO_FAIL]: state => {
    state.meta.isLoadingDiscountInfo = false
    state.meta.drugDataLoaded = true
  },
  [searchTypes.RESET_DISCOUNT_INFO]: state => {
    state.discount = null
  }
})
