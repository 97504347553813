import React from 'react'

const logos = [
  { name: 'cvs', width: 156 },
  { name: 'riteaid', width: 126 },
  { name: 'walgreens', width: 115 },
  { name: 'walmart', width: 112 },
  { name: 'giant', width: 100 },
  { name: 'wegmans', width: 100 },
]

const HomeLogos = ({ isFrame }) => {
  if (isFrame) return null

  return (
    <div className='c-search-form__logos-container'>
      <div className='c-paragraph--sm c-paragraph--center'>
        <b>Accepted at most U.S. pharmacies including:</b>
      </div>
      <div className='c-search-form__logos'>
        {logos.map(({ name, width }) => (
          <img width={width} key={name} src={`/images/${name}.png`} alt={name} />
        ))}
      </div>
    </div>
  )
}

export default HomeLogos
