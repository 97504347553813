import React, {useState} from 'react';
import {Modal} from 'antd';
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {FaMinus, FaPlus, FaXmark} from "react-icons/fa6";

// Define the component with props destructuring
const DiscountCard = ({width, height, home, card}) => {
    const imageSrc = card === "volunteer"
        ? '/images/5598.png'
        : '/images/prescriptioncard.png';

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="c-discountcard--top" tabIndex="1">
            <div
                className={`c-discountcard${home ? '-home' : ''}`}
                style={{position: 'relative'}}>
                <div className={`c-discountcard${home ? '-home' : ''}__bg`}/>
                <img
                    width={width || 465}
                    height={height || 261}
                    src={imageSrc}
                    alt="Visual of DDN discount prescription card"
                    className="c-discountcard-image"
                    onClick={showModal}
                />
                <Modal title={null} footer={null} open={isModalOpen} onCancel={handleCancel}
                       className="c-discountcard-image">
                    <TransformWrapper
                        initialScale={1}
                        minScale={1}
                        maxScale={2}
                        initialPositionY={200}
                    >
                        {({zoomIn, zoomOut, resetTransform, ...rest}) => (
                            <React.Fragment>
                                <div className="tools">
                                    <button onClick={() => zoomIn()}><FaPlus/></button>
                                    <button onClick={() => zoomOut()}><FaMinus/></button>
                                    <button onClick={() => handleCancel()}><FaXmark/></button>
                                </div>
                                <TransformComponent>
                                    <img src={imageSrc} alt="test"/>
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                </Modal>
            </div>
        </div>
    );
};

export default DiscountCard;
