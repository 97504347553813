import React, { useState, useEffect } from 'react';
import { FaUniversalAccess } from "react-icons/fa";

const COOKIE_CONSENT_KEY = 'cookieConsent';
// const COOKIE_EXPIRATION_DAYS = 30;

const Footer = ({ isFrame }) => {
  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
    const hasConsent = localStorage.getItem(COOKIE_CONSENT_KEY);

    if (!hasConsent && !isFrame) {
      setShowCookieConsent(true);
    }
  }, [isFrame]);

  const handleCookieConsent = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, 'true');
    setShowCookieConsent(false);
  };

  return (
    <>
      {showCookieConsent && (
        <div className="cookie-consent-banner">
          <div className="cookie-consent-content">
            <span className="cookie-consent-text">
              We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.
              <a href="/privacy-policy-terms-of-use">View details</a>
            </span>
            <button className="cookie-consent-button" onClick={handleCookieConsent}>
              Accept
            </button>
          </div>
        </div>
      )}
      <footer className='g-footer'>
        <div className="g-footer__ada">
          <FaUniversalAccess/>
          <div className='g-footer__links'>
            <a href='/accessibility-statement' className='g-link'>
             Accessibility Statement
            </a>
          </div>
        </div>
        <div className='g-footer__copyright'>
          <span>© {(new Date().getFullYear())} Discount Drug Network, LLC. All Rights Reserved.</span>
          <div className='g-footer__links'>
            <a href='/privacy-policy-terms-of-use' className='g-link'>
              Privacy Policy & Terms of Use
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
