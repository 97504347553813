import React, { useEffect, useRef } from 'react'
import { Select, DatePicker } from 'antd'
import { CloseIcon } from 'shared/components'
import { checkInactiveDate, DATE_DISPLAY_FORMAT } from 'utils/tools'

export const DdnSelect = (props) => {
  const { items, id, field, label, onSelect, full, ..._props } = props
  if (!items.length) return null

  return (
    <div className={`g-select ${full ? 'g-select--full' : ''}`}>
      <div className='g-select__label'>{label}</div>
      <Select
        className={''}
        placeholder={`Select ${label}`}
        onChange={onSelect}
        {..._props}
      >
        {items.map((item) => {
          let value = item[field] || item[id];
          return (
            <Select.Option key={item[id]} value={value} className='g-select__option'>
              {item[id]}
            </Select.Option>
          )
        })}
      </Select>
    </div>
  )
}

export const DdnInput = React.forwardRef(
  ({
    additionalClassName = '',
    clearInput,
    ..._props
  }, ref) => {
    const { value } = _props
    return (
      <div className='g-input__container'>
        <input
          {..._props}
          className={`g-input ${additionalClassName}`}
          ref={ref}
        />
        {value && clearInput
          ? <CloseIcon className='g-input__icon' onClick={clearInput} />
          : null}
      </div>
    )
  }
)

export const FormInput = ({ label, full, required, ..._props }) => {
  const { onChange } = _props
  const clearInput = () => onChange('')
  const ref = useRef();

  useEffect(() => {
    if (ref && _props["autoFocus"]) {
      ref.current.focus();
    }
  }, [ref, _props]);

  return (
    <>
      <div className={`
      g-input__form-item
      ${required ? 'g-input__form-item--required' : ''}
      ${full ? 'g-input__form-item--full' : ''}
    `}>
        <div className='g-input__label'>{label}</div>
        <DdnInput
          {..._props}
          clearInput={clearInput}
          ref={ref}
        />
      </div>
      {required && (
        <div className='g-input__required'><i>Required</i></div>
      )}
    </>
  )
}

export const FormAreaInput = ({ label, additionalClassName, ..._props }) => {
  const { onChange, value } = _props
  const clearInput = () => onChange('')
  return (
    <div className={`
      g-input__form-item
    `}>
      <div className='g-input__label'>{label}</div>
      <div className='g-input__textarea-container'>
        <textarea
          className={`g-input ${additionalClassName}`}
          rows='5'
          {..._props}
        />
        {value && clearInput
          ? <CloseIcon className='g-input__icon' onClick={clearInput} />
          : null}
      </div>
    </div>
  )
}

export const DdnDatePicker = (props) => {
  return <DatePicker
    {...props}
    format={DATE_DISPLAY_FORMAT}
    disabledDate={checkInactiveDate}
    allowClear={false}
    className='g-datepicker'
    dropdownClassName='g-datepicker-dropdown'
  />
}

export const InputWarn = ({ label, value, maxLen }) => {
  const leftChars = maxLen - (!value ? 0 : value.length)
  const error = value && value.length > maxLen
  return <div className='u-right u-mt8'>
    {error
      ? <div className='g-content__info g-content__error'>{label} is at most {maxLen} Characters</div>
      : (
        <div className='g-content__info'>
          {leftChars < maxLen ? `${leftChars} Characters left` : `${maxLen} Characters`}
        </div>
      )
    }
  </div>
}
