export const contactFaqsData = [
  {
    question: `What prescription drugs work with the FREE Prescription Discount Card?`,
    answer: `
    All FDA approved prescription medications can be eligible with the Prescription Discount Card. We use group buying power to negotiate discounts up to 80% on behalf of our card holders. If you don’t have insurance, have high-deductible insurance, a high co-pay, or need a prescription that is not covered by your insurance, the discount card often has the best price. It is free to check your price online or at your pharmacy by showing your card and asking, “What’s my Discount Drug Card price?”. Your pharmacist will search the BIN and your Group ID to check the price.
    `
  },
  {
    question: `Are there any enrollment restrictions with the Prescription Discount Card?`,
    answer: `
      Everyone is eligible to participate and benefit with the discount card.
      There are no enrollment restrictions, the card is free, and never expires.
      The discount card uses the power of our network and our large quantity of card holders to obtain low prices on prescription medications.
    `
  },
  {
    question: `Which pharmacies accept the Prescription Discount Card?`,
    answer: `The discount card is accepted at over 70,000+ pharmacies in the United States, Pureto Rico, and the U.S. Virgin Islands. You can use your free discount card at national pharmacies like CVS, Walgreens, and Rite Aid. You can also use the discount card at grocery store pharmacies, Walmart, and many more locations. Search your medications on the website and we will help you find the best location to find the lowest price!`
  },
  {
    question: `Whom should I contact if I have a problem using my Prescription Discount Card?`,
    answer: `
      Customer and pharmacist support can be reached at: 877-537-5537.
      The free prescription discount card is always active and entitles card holders to all prescription drug benefits associated with the BIN and Group Number (per state and federal law).
      Card holders always have the right to pay the lowest cost available on their prescription medications and should always check the discount card price.
    `
  },
]

export const couponFaqsData = [
  {
    question: `What do I do if my pharmacy is not familiar with this savings card?`,
    answer: `
      If your pharmacist is unable to process your savings card for any reason,
      please have them call 1-877-537-5537 (M-F 9AM-6PM EST).
    `
  },
  {
    question: `Can I use the savings card along with my health insurance?`,
    answer: `This discounted price may be lower than your health insurance co-pay.
      However, it cannot be used in conjunction with your health insurance and it can not be used to lower your co-pay.
      Ask your pharmacist for help in receiving the best possible price.`
  },
  {
    question: `What do I do if the pharmacist runs my savings card and the price on my savings card is not correct?`,
    answer: `While we do our best to be as accurate as possible, prescription prices are constantly changing.
      Please make sure to report any and all issues to support@discountdrugnetwork.com.
      The final price is determined by your local pharmacy. Please ask your pharmacist to help you find the lowest possible price.`
  },
]
