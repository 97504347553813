import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {setFrameHeight} from 'utils/iframe';
import {HomeSearch} from 'features/search/components';
import {selectIsIframe} from 'features/auth/redux/authSelector';
import {Container, SEO} from 'shared/components';
import FaqItem from 'shared/components/FaqItem';
import {contactFaqsData} from 'shared/constants/faqs';
import DiscountCard from "../../shared/assets/images/DiscountDrugNetwork";

const Search = () => {
    const isFrame = useSelector(selectIsIframe);

    useEffect(() => {
        if (isFrame) {
            setFrameHeight();
        }
    }, [isFrame]);

    return (
        <div className="c-search">
            <SEO
                title="Prescription Discounts up to 80%! - Discount Drug Network"
                description="Prescription Discount Card Program by the Discount Drug Network"
            />
            <DiscountCard width="400px" height="100px" card="volunteer"/>
            <HomeSearch isFrame={isFrame}/>
            {!isFrame && (
                <>
                    <Container additionalClassName="c-search__faqs">
                        <div className="c-heading c-heading--sm c-heading--center">FAQs</div>
                        {contactFaqsData.map((d) => (
                            <FaqItem key={d.question} {...d} />
                        ))}
                        <div className="c-paragraph c-paragraph--center u-mt30 c-paragraph__faq-after">
                            Additional Questions?{' '}
                            <Link className="c-paragraph__link" to="mailto:support@discountdrugnetwork.com">
                                <b>Support@DiscountDrugNetwork.com</b>
                            </Link>
                        </div>
                    </Container>
                </>
            )}
        </div>
    );
};

export default Search;
