import React, { useEffect } from 'react'
import { SEO } from 'shared/components'

export default () => {
    useEffect(() => {
        window.scrollTo({ top: 0 })
    }, [])

    return (
        <div className='c-accessibility-statement'>
            <SEO title='Accessibility Statement - Discount Drug Network' description='Discount Drug Network presents the standards of use of website and associated discount programs.' />
            <div className='c-accessibility-statement__heading c-heading c-heading--center'>
                Accessibility Statement
            </div>
            <div className='c-paragraph--sm'>
                Discount Drug Network is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone and applying the relevant accessibility standards to achieve these goals. We are dedicated to complying with the Web Content Accessibility Guidelines (WCAG) 2.1, Level A standards.
            </div>
        </div>
    )
}
