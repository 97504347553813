import React from 'react'
import { Collapse } from 'antd'
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

const filterCollapseProps = {
  bordered: false,
  expandIconPosition: 'right',
  expandIcon: ({ isActive }) => isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />
}

export default ({ question, answer }) => {
  return (
    <div className='c-faq-item'>
      <Collapse
        {...filterCollapseProps}
      >
        <Collapse.Panel
          key={1}
          tabIndex={6}
          header={
            <div className='c-paragraph c-paragraph__faq'>
              {question}
            </div>
          }
        >
          <div className='c-paragraph'>{answer}</div>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}
